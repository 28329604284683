<template>
  <div class="stack-context-menu">
    <template v-if="menu.length && menu[0].length">
      <div v-for="(group, i) in menu" :key="i" class="stack-context-menu__group">
        <component
          :is="item.attrs ? 'a' : 'div'"
          v-for="item in group"
          :key="item.key"
          v-bind="item.attrs"
          class="stack-context-menu__item standart-title"
          :class="{ [`stack-context-menu__item--${item.color}`]: !!item.color }"
          :disabled="item.disabled"
          @click="onClick(item)"
        >
          {{ $t(`actions.${item.key}`) }}
        </component>
      </div>
    </template>
    <template v-else>
      <div class="stack-context-menu__noact standart-text">
        <div v-if="isSuspended" class="standart-title">
          {{ $t('noact.suspended') }}
        </div>
        <div>{{ $t('noact.noactions') }}</div>
      </div>
    </template>
  </div>
</template>

<script>
import showErrorModal from '@/mixins/showErrorModal';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import renameRouter from '@/mixins/renameRouter';
import changeRouterDescription from '@/layouts/Stack/mixins/changeRouterDescription';
export default {
  name: 'RouterContextMenu',
  mixins: [renameRouter, showErrorModal, changeRouterDescription],
  props: {
    tariff: {
      type: Object,
      required: true,
    },
    // isLoading: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  data() {
    return {
      res: '',
      timerId: '',
      timerCnt: 0,
      isProcessing: false,
      snapId: '',
      imageId: '',
    };
  },
  computed: {
    serverId() {
      return this.tariff.attachments.length > 0 ? this.tariff.attachments[0].server_id : '';
    },
    servers() {
      return this.$store.getters['moduleStack/servers'];
    },
    projectId() {
      return this.$store.getters['moduleStack/project_id'];
    },
    date() {
      return this.$d(Date.now(), 'short').replaceAll('.', '_');
    },
    time() {
      return this.$d(Date.now(), 'long').replace(/[^\d]/g, '_');
    },
    id() {
      return this.tariff.id;
    },
    processingStatus() {
      // return this.tariff.specialstatus.toNumber();
      return 2;
    },
    isSuspended() {
      return this.tariff.abusesuspend === 'on' || this.tariff.employeesuspend === 'on';
    },
    menu() {
      const networkAdminStatus = this.tariff.admin_state_up !== true ? 'true' : 'false';
      const arr = [];
      arr.push([
        {
          key: 'descr',
          handler: 'descr',
        },
        {
          key: 'edit',
          id: this.id,
          handler: 'func',
          action: {
            network: {
              name: this.tariff.name,
            },
          },
        },
        {
          key: networkAdminStatus,
          id: this.id,
          handler: 'state',
          label: !this.tariff.admin_state_up,
          action: {
            admin_state_up: !this.tariff.admin_state_up,
          },
        },
        {
          key: 'delete',
          color: 'del',
          handler: 'delete',
          action: 'delete',
        },
      ]);
      return arr;
    },
  },
  mounted() {},
  beforeDestroy() {
    if (this.timerId) clearTimeout(this.timerId);
  },
  methods: {
    onClick(item) {
      if (item.disabled) return;
      if (item.action && item.handler === 'state') this.askSure(item);
      if (item.action && item.handler === 'delete') this.deleteRouter(item);
      if (item.action && item.key === 'edit') this.renameRouter(this.tariff.name, item);
      if (item.handler === 'descr') this.changeRouterDescription(this.tariff);
    },
    changeAdminState(item) {
      return this.$store.dispatch('moduleStack/updateRouter', {
        router: item.action,
        id: item.id,
        item: 'admin_state_up',
      });
    },
    askSure(action) {
      const that = this;
      let self;
      const selfName = 'ServerAction';
      let res = '';
      let mode = '';
      let time;
      this.$modals.open({
        name: selfName,
        html: true,
        text: this.$t('modal.sure.text', { msg: this.$t(`actions.${action.label}`).toLowerCase() }),
        onOpen(inst) {
          self = inst;
        },
        footer: {
          confirm: {
            props: {
              title: this.$t('modal.sure.confirm'),
            },
            on: {
              click: () => {
                self.text = null;
                self.component = BaseLoader;
                self.closable = false;
                self.footer = false;
                time = Date.now();
                this.isProcessing = true;

                this.changeAdminState(action)
                  .then(data => {
                    this.isProcessing = false;
                    mode = data;
                    res = 'ok';
                  })
                  .catch(() => {
                    res = 'fail';
                  })
                  .finally(() => {
                    const delay = Date.now() - time < 1000 ? 1000 : 0;
                    setTimeout(() => {
                      self.component = null;
                      self.closable = true;
                      self.text = this.$t(`modal.sure.${res}`, {
                        msg: this.$t(`modal.sure.${mode}`),
                      });
                      self.footer = {
                        centered: true,
                        confirm: {
                          props: { title: this.$t('modal.sure.close') },
                          on: { click: () => this.$modals.close() },
                        },
                      };
                    }, delay);
                  });
              },
            },
          },
          cancel: {
            on: {
              click: () => {
                that.$modals.close();
              },
            },
          },
        },
      });
    },
    deleteRouter() {
      const that = this;
      let self;
      const selfName = 'ServerAction';
      let res = '';
      let time;
      this.$modals.open({
        name: selfName,
        html: true,
        text: this.$t('delete', { name: this.tariff.name, id: this.id }),
        onOpen(inst) {
          self = inst;
        },
        footer: {
          confirm: {
            props: {
              title: this.$t('modal.sure.confirm'),
              color: 'error',
            },
            on: {
              click: () => {
                self.text = null;
                self.component = BaseLoader;
                self.closable = false;
                self.footer = false;
                time = Date.now();
                this.isProcessing = true;
                this.deleteCurrentRouter(this.id).then(async data => {
                  // console.log(data);
                  await this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'routers');
                });
                res = 'success';
                const delay = Date.now() - time < 1000 ? 1000 : 0;
                setTimeout(() => {
                  self.component = null;
                  self.closable = true;
                  self.size = 'small';
                  self.text = this.$t(`modal.sure.${res}`);
                  self.footer = {
                    centered: true,
                    confirm: {
                      props: { title: this.$t('modal.sure.close') },
                      on: {
                        click: () => {
                          this.$modals.close();
                          this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'routers');
                          this.$store.dispatch('moduleStack/fetchQuotasNetwork', this.project);
                          this.$router.push({ name: 'viewStackRouter' }).catch(() => {});
                        },
                      },
                    },
                  };
                }, delay);
              },
            },
          },
          cancel: {
            on: {
              click: () => {
                that.$modals.close();
              },
            },
          },
        },
      });
    },
    showResultModal(text) {
      this.$modals.open({
        name: 'Result',
        text,
        size: 'small',
        footer: {
          centered: true,
          confirm: {
            props: { title: this.$t('close') },
            on: {
              click: () => this.$modals.close(),
            },
          },
        },
      });
    },
    deleteCurrentRouter(router) {
      return this.$store
        .dispatch('moduleStack/deleteRouter', {
          router: router,
        })
        .catch(e => {
          this.showResultModal(this.$t('modal.sure.fail'));
        });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "delete": "Удалить роутер: <b>%{name}</b> (id: %{id})?",
    "actions": {
      "edit": "Переименовать",
      "false": "Выключить",
      "true": "Включить",
      "process": "обновляем...",
      "restart": "перезагрузить",
      "dhcp": "Включить|Выключить DHCP",
      "delete": "Удалить роутер",
      "descr": "Изменить описание",
      "confirm" : "Подтвердить"
    },
    "noact": {
      "noactions": "Нет доступных действий.",
      "suspended": "Услуга остановлена администратором."
    },
    "sure": {
      "confirm": "Подтвердить",
      "close": "Закрыть"
    },
    "modal": {
      "description": "Описание роутера:",
      "sure": {
        "text": "Вы собираетесь <b>%{msg}</b> роутер. Вы уверены?",
        "confirm": "Подтвердить",
        "close": "Закрыть",
        "true": "включён",
        "false": "выключен",
        "success": "Роутер успешно удалён.",
        "ok": "Роутер успешно %{msg}.",
        "fail": "Роутер нельзя удалить, пока он связан с одной или несколькими подсетями."
      }
    }
  }
}
</i18n>
<!--//        "success": "Запрос выполняется. Пожалуйста, дождитесь изменения статуса.",-->

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';

$vcm-colors = {
  primary: $primary-color,
  success: $success-color,
  del: $color-red.light,
}
$vcm-colors-hover = {
  primary: $color-blue.medium,
  success: darken($success-color, 20%),
  del: darken($color-red.light, 20%),
}

.stack-context-menu {

  &__group {
    & + & {
      margin-top: 2.5rem;

      +breakpoint(sm-and-up) {
        margin-top: (1.25rem * 3);
      }
    }
  }
  &__item {
    display: block;
    color: var(--text);
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s ease;

    &:first-letter {
      text-transform: uppercase;
    }

    &:focus {
      outline: none;
    }
    &:hover {
      color: $primary-color;
    }

    &[disabled] {
      pointer-events: none;
      cursor: default;
      opacity: 0.6;
    }

    & + & {
      margin-top: 1.25rem;
    }

    for $key, $value in $vcm-colors {
      &--{$key} {
        color: $value;
      }
    }
    for $key, $value in $vcm-colors-hover {
      &--{$key}:hover {
        color: $value;
      }
    }

  }

  &__noact {
    div + div {
      margin-top: 0.75rem;
    }
  }
}
</style>
<style lang="stylus">
@require '~@/assets/styles/vars/variables';

.stack-context-menu {
  .action-popup & {
    padding: 1.5rem 1rem;
    width: calc(100vw - 0.75rem);

    +breakpoint(sm-and-up) {
      padding: 1.5rem;
      width: 260px;
    }
  }
}
</style>
