import AttachSubnet from '@/layouts/Stack/components/AttachSubnet.vue';
import showErrorModal from '@/mixins/showErrorModal';
import storeMixin from '@/layouts/Stack/mixins/index';
import Vue from 'vue';
import BaseLoader from '@/components/BaseLoader/BaseLoader.vue';

export default {
  mixins: [showErrorModal, storeMixin],
  data() {
    return {
      device_id: '',
      data: {},
      timerId: '',
      timerCnt: 0,
      isProcessing: false,
    };
  },
  computed: {},
  methods: {
    attachSubnet: function (instance) {
      const that = this;
      let self;
      let time;
      let res = '';
      return new Promise(() => {
        const selfName = 'AttachSubnet';
        const router = instance.id;
        this.$modals.open({
          name: selfName,
          size: 'medium',
          component: AttachSubnet,
          closeOnBackdrop: false,
          props: {
            router: instance.id,
            instance: instance,
          },
          on: {
            change: data => {
              instance = data;
              Vue.set(self.footer.confirm.props, 'disabled', false);
            },
            notready: () => {
              Vue.set(self.footer.confirm.props, 'disabled', true);
            },
            ready: () => {
              Vue.set(self.footer.confirm.props, 'disabled', false);
            },
          },
          onOpen(inst) {
            self = inst;
          },
          footer: {
            confirm: {
              props: { title: this.$t('sure.confirm'), loading: false },
              on: {
                click: () => {
                  self.text = null;
                  let mode;
                  self.component = BaseLoader;
                  self.closable = false;
                  self.footer = false;
                  time = Date.now();
                  this.isProcessing = true;
                  // console.log(instance);

                  this.addNewPort(instance).then(async data => {
                    // console.log(data);
                    if (data && data.error) {
                      // if (res === 'fail') this.$modals.close();
                      let delay = Date.now() - time < 1000 ? 1000 : 0;
                      setTimeout(() => {
                        self.component = null;
                        self.closable = true;
                        self.size = 'small';
                        self.text = data.text;
                        self.footer = {
                          centered: true,
                          confirm: {
                            props: { title: this.$t('sure.close') },
                            on: { click: () => this.$modals.close() },
                          },
                        };
                      }, delay);
                    } else
                      await this.addRouterInterface(data.port.id, router).then(async data => {
                        if (data) {
                          this.isProcessing = false;

                          mode = data;
                          res = 'success';
                          this.newFetch();
                        }
                      });
                    let delay = Date.now() - time < 1000 ? 1000 : 0;
                    setTimeout(() => {
                      self.component = null;
                      self.closable = true;
                      self.size = 'small';
                      self.text = this.$t(`sure.${res}`, {
                        msg: this.$t(`sure.${mode}`),
                      });
                      self.footer = {
                        centered: true,
                        confirm: {
                          props: { title: this.$t('sure.close') },
                          on: { click: () => this.$modals.close() },
                        },
                      };
                    }, delay);
                  });
                  // .catch(() => {
                  //   this.$modals.close();
                  // res = 'fail';
                  // mode = res;
                  // })
                  // .finally(() => {
                  //   if (res === 'fail') this.$modals.close();
                  //   const delay = Date.now() - time < 1000 ? 1000 : 0;
                  //   setTimeout(() => {
                  //     self.component = null;
                  //     self.closable = true;
                  //     self.size = 'small';
                  //     self.text = this.$t(`sure.${res}`, {
                  //       msg: this.$t(`sure.${mode}`),
                  //     });
                  //     self.footer = {
                  //       centered: true,
                  //       confirm: {
                  //         props: { title: this.$t('sure.close') },
                  //         on: { click: () => this.$modals.close() },
                  //       },
                  //     };
                  //   }, delay);
                  // });
                },
              },
            },
          },
          cancel: {
            on: {
              click: () => {
                that.$modals.close();
              },
            },
          },
        });
      });
    },
    addNewPort(payload) {
      return this.$store.dispatch('moduleStack/createPort', payload).catch(e => {
        console.log(e);
        if (
          e &&
          e.data &&
          e.data.NeutronError &&
          e.data.NeutronError.type === 'IpAddressAlreadyAllocated'
        ) {
          const text = e.data.NeutronError.message;
          console.log(text);
          const parseText = text.split(' ');
          // console.log(parseText);
          // console.log(parseText[2]);
          const subnet = parseText.at(-1);
          const subnetName = this.$store.state.moduleStack.subnets.find(x => x.id === subnet).name;
          // console.log(parseText.at(-1));
          const hardCodeForever = `IP-адрес <b>${parseText[2]}</b> уже привязан к роутеру от подсети <b>${subnetName}</b>.`;
          console.log(hardCodeForever);

          // console.log(e.data.NeutronError.message);
          // this.$modals.close();
          this.showResModal(hardCodeForever);
          return { text: hardCodeForever, error: true };
        } else {
          console.log('22222222222222222222222222');
          this.showResModal(e.data.NeutronError.message);
        }
      });
    },
    addRouterInterface(port, router) {
      const params = {
        port_id: port,
        router_id: router,
      };
      return this.$store.dispatch('moduleStack/addRouterInterface', params).catch(e => {
        console.log(e);
        this.showResModal(e);
      });
    },
    makeModal(props = {}) {
      this.$modals.open({
        name: 'AddModal',
        size: 'small',
        onOpen: inst => (this.modal = inst),
        onClose: () => (this.modal = null),
        onDismiss: () => (this.modal = null),
        ...props,
      });
    },
    showResModal(res, props = {}) {
      if (!this.modal) this.makeModal(props);
      Vue.set(this.modal, 'component', null);
      Vue.set(this.modal, 'closable', true);
      Vue.set(this.modal, 'html', true);
      Vue.set(this.modal, 'text', res);
      Vue.set(this.modal, 'footer', {
        centered: true,
        confirm: {
          props: { title: this.$t('sure.close') },
          on: {
            click: () => {
              this.$modals.close();
            },
          },
        },
      });
    },
  },
};
